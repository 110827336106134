
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { routeParamsToVerse } from '@/router/route_to_verse';
import SectionHeader from '@/components/global/SectionHeader.vue';
import VerseBar from '@/components/verse_navigation/VerseBackAndForth.vue';
import VerseText from '@/components/variants/VerseText.vue';
import VariantsContributors from '@/components/variants/VariantsContributors.vue';
import VariantsTable from '@/components/variants/VariantsTable.vue';
import HowToQuote from '@/components/global/HowToQuote.vue';
import { isLoaded } from '@/interfaces/RemoteData';
import { getVariants, canonical, getCanonical, variantResults, selectedWord } from '@/api/variants';
import { webWatcher, variantsWeb } from '@/api/web';
import { makeSearchTerm } from '@/components/variants/letterMultiplier';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    SectionHeader,
    VerseBar,
    VerseText,
    VariantsTable,
    VariantsContributors,
    HowToQuote,
  },
  setup() {
    const {
      t,
    } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();
    const title = computed(
      () => `${t('navigation.variant_readings')} ${t('global.sura')} ${route.params.sura} ${t(
        'global.verse',
      )} ${route.params.verse}`,
    );
    const description = computed(
      () => `${t('navigation.variant_readings')} ${t('global.sura')} ${route.params.sura} ${t(
        'global.verse',
      )} ${route.params.verse}`,
    );
    const canonicalurl = computed(
      () => `https://corpuscoranicum.de/${route.params.lang}/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/variants`,
    );
    const alternate_de = computed(
      () => `https://corpuscoranicum.de/de/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/variants`,
    );
    const alternate_en = computed(
      () => `https://corpuscoranicum.de/en/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/variants`,
    );
    const alternate_fr = computed(
      () => `https://corpuscoranicum.de/fr/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/variants`,
    );
    const meta = computed(() => ({
      title: title.value,
      description: description.value,
      link: [
        {
          rel: 'canonical', href: canonicalurl.value,
        },
        {
          rel: 'alternate', hreflang: 'de', href: alternate_de.value,
        },
        {
          rel: 'alternate', hreflang: 'en', href: alternate_en.value,
        },
        {
          rel: 'alternate', hreflang: 'fr', href: alternate_fr.value,
        },
        {
          rel: 'alternate', hreflang: 'x-default', href: alternate_de.value,
        },
      ],
      htmlAttrs: {
        lang: route.params.lang,
      },
    }));
    useMeta(meta);
    webWatcher(route, 'variants', variantsWeb);

    getVariants(routeParamsToVerse(route.params));
    getCanonical();

    const search = ref<string>('');

    watch([() => route.params], () => {
      if (route.name === 'VerseVariants') {
        getVariants(routeParamsToVerse(route.params));
      }
    });

    watch(selectedWord, () => {
      console.log(selectedWord.value);
      if (selectedWord.value !== undefined) {
        console.log('running selected word', selectedWord.value);
        const el = document.getElementById(`word-column-${+selectedWord.value + 1}`);
        if (el) {
          console.log(el);
          el.scrollIntoView({
            behavior: 'smooth',
            inline: 'end',
            block: 'nearest',
          });
        }
      }
    });

    const searchTerm = computed(() => makeSearchTerm(search.value));

    return {
      isLoaded,
      variantResults,
      variantsWeb,
      canonical,
      search,
      searchTerm,
    };
  },
});
